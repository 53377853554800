import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        clusters: [],
        prodClusters: [],
        povClusters: [],
        sortModel: [],
        prodSortModel: [],
        povSortModel: [],
        syncInfo: [{
            label: 'Last sync time',
            key: 'lastSyncTime',
            value: "",
            color: '#006400'
        }, {
            label: 'Processed files count',
            key: 'fileProcessedCount',
            value: "",
            color: '#006400'
        }]
    },
    reducers: {
        setClusters: (state, action) => {
            state.clusters = action.payload
        },
        setProdClusters: (state, action) => {
            state.prodClusters = action.payload
        },
        setPOVClusters: (state, action) => {
            state.povClusters = action.payload
        },
        setRefreshData: (state, action) => {
            state.refreshData = action.payload
        },
        setSortModel: (state, action) => {
            state.sortModel = action.payload
        },
        setProdSortModel: (state, action) => {
            state.prodSortModel = action.payload
        },
        setPOVSortModel: (state, action) => {
            state.povSortModel = action.payload
        },
        setSyncInfo: (state, action) => {
            for(const key in action.payload) {
                const infoObj = state.syncInfo.find(obj => obj.key === key)
                if (infoObj) {
                    Object.assign(infoObj, {
                        value: action.payload[key]
                    })
                }
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setClusters, 
    setRefreshData, 
    setSortModel, 
    setSyncInfo,
    setPOVClusters,
    setProdClusters,
    setPOVSortModel,
    setProdSortModel
 } = dashboardSlice.actions

export default dashboardSlice.reducer