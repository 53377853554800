import { createSlice } from '@reduxjs/toolkit'

export const clusterSlice = createSlice({
  name: 'clusters',
  initialState: {
    clusters: [],
    sortModel: []
  },
  reducers: {
    setClusters: (state, action) => {
        state.clusters = action.payload
    },
    updateCluster: (state, action) => {
        const { clusterName , fieldName, fieldValue } = action.payload
        const cluster = state.clusters.find(cluster => cluster.clusterName === clusterName)
        if (cluster) {
            const impose = {}
            impose[fieldName] = fieldValue
            Object.assign(cluster, impose)
        }
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setClusters, setSortModel, updateCluster } = clusterSlice.actions

export default clusterSlice.reducer