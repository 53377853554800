import { Switch } from '@mui/material';
import React from 'react';

const ClusterSwitch = ({cellValue, handleClusterStateChange, fieldName}) => {
    const handleChange = (event) => {
        const payload = {
            'clusterName': cellValue.row.clusterName,
            'fieldName': fieldName,
            'fieldValue': event.target.checked
        }
        handleClusterStateChange(payload)
    }
        return (
            <Switch
                checked={cellValue.value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        )

}

export default ClusterSwitch;
